import React from 'react';
import bg1 from './../../Assets/Fcare/Picture1.png';
import bg2 from './../../Assets/Fcare/Picture2.png';
import bg3 from './../../Assets/Fcare/Picture3.png';
import bg4 from './../../Assets/Fcare/Picture4.png';
import bg5 from './../../Assets/Fcare/Picture5.png';
import bg6 from './../../Assets/Fcare/Picture6.png';
import bg7 from './../../Assets/Fcare/Picture7.png';
import bg8 from './../../Assets/Fcare/Picture8.png';
import bg9 from './../../Assets/Fcare/Picture9.png';
import bg10 from './../../Assets/Fcare/Picture10.png';
import bg11 from './../../Assets/Fcare/Picture11.png';
import bg12 from './../../Assets/Fcare/Picture12.png';
import bg13 from './../../Assets/Fcare/Picture13.png';
import bg14 from './../../Assets/Fcare/Picture14.png';
import bg15 from './../../Assets/Fcare/Picture15.png';
import bg16 from './../../Assets/Fcare/Picture16.png';
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";


export default function AllPage() {
return(
<div className="container Pages-font-size">
  <h1 class="mt-5 mb-5 text-center">BUNION & OVERLAPPING TOES</h1>
  <div class="row">
  <div class="col-sm-4">
  <img src={bg2} class="img-fluid w-100" alt="Responsive image"/>
  </div>
  <div class="col-sm-4">
  <img src={bg1} class="img-fluid w-100" alt="Responsive image"/>
  </div>
  <div class="col-sm-4">
  <img src={bg3} class="img-fluid w-100" alt="Responsive image"/>
  </div>
  </div>
  <p class="mt-4">
  A hallux valgus deformity, commonly called a bunion, is when there is medial deviation of the first metatarsal and lateral deviation of the great toe (hallux). The condition can lead to painful motion of the joint. 
  Hallux valgus is the commonest forefoot deformity, with an estimated prevalence of around 30%. It causes symptoms on the medial edge of the foot, the sole, and the small toes. Non-operative treatment may alleviate symptoms but does not correct the deformity of the big toe. 
  </p>
        <h1>CORNS / CALLUSES</h1>
        <div class="row">
        <div class="col-sm-4 justify-content-center">
        <img class="w-100 rounded mt-2" src={bg4}/>
        <img class="w-100 rounded mt-5" src={bg5}/>
        </div>
        <div class="col-sm-8  mt-2 mb-5">
            <p>
Corns and calluses are hard, thickened areas of skin that form as a consequence of rubbing, friction or pressure on the skin.Corns generally occur on the tops and sides of the toes. A hard corn is a small patch of thickened, dead skin with a small plug of skin in the center.

A hard corn is a small patch of thickened, dead skin with a small plug of skin in the center. A soft corn has a much thinner surface, appears whitish and rubbery, and usually occurs between the toes. Seed corns are clusters of tiny corns that can be very tender if they are on a weight-bearing part of the foot. Seed corns tend to occur on the bottom of the feet, and some doctors believe this condition is caused by blocked sweat ducts.

Calluses are hard and rough-feeling areas of skin that can develop on hands, feet or anywhere there is repeated friction - even on a violinist&#39;s chin. Like corns, calluses have several variants. The common callus usually occurs when there has been a lot of rubbing against the hands or feet. A plantar callus is found on the bottom of the foot.

Other risk factors for developing a corn or callus include foot deformities and wearing shoes or sandals without socks, which leads to friction on the feet. Rubbing or pressure can cause either soft corns or plantar calluses. The best way to prevent corns and calluses is to buy shoes that fit well. Avoid tight-fitting or narrow-toed shoes. Avoid heels or shoes that force pressure on your toes.
</p>
            <h4>CORRECTIONS</h4>
            <ol>
                <li>Most corns and calluses gradually disappear when the friction or pressure stops. Custom made insoles(offloading) help to reduce the pressure on the problem areas and eases pain and helps in faster healing.</li>
                <li>Consult a doctor if the problem persists</li>
            </ol>
        </div>
        </div>
        <h1>PLANTAR FASCIITIS</h1>
       <div class="row">
        <div class="col-sm-4 justify-content-center">
        <img class="w-100 rounded mt-2" src={bg6}/>
        </div>
        <div class="col-sm-8  mt-2 mb-5">
            <p>
The plantar fascia is a flat band of tissue (ligament) that connects your heel bone to your toes. The plantar fascia is the largest ligament in the human body. It supports the arch of your foot. If you strain your plantar fascia, it gets weak, swollen, and irritated (inflamed). Then your heel or the bottom of your foot hurts when you stand or walk.

Plantar fasciitis is one of the most common causes of heel pain. It involves inflammation of a thick band of tissue that runs across the bottom of your foot and connects your heel bone to your toes. Plantar fasciitis is most commonly caused by strain injury causing micro tears to the ligament as it attaches to the heel bone or other areas of tightness on the sole of the foot.

Plantar fasciitis commonly causes stabbing pain that usually occurs with your first steps in the morning. As you get up and move more, the pain normally decreases, but it might return after long periods of standing or after rising from sitting.

There are more than 10 million cases per year in India and is easily identifiable . The symptoms include stabbing pain near the heel. Pain might be worse in the morning and with prolonged standing, running or jumping. Treatments include physiotherapy, stretching exercises, shoe inserts, pain relievers, steroid injections and surgery. 
</p>
        </div>
        </div>
        <h1>HEEL PAIN</h1>
       <div class="row">
        <div class="col-sm-4 justify-content-center">
        <img class="w-100 rounded mt-2" src={bg7}/>
        </div>
        <div class="col-sm-8  mt-2 mb-5">
            <p>
Heel pain is a very common foot problem. The sufferer usually feels pain either under the heel (plantar fasciitis) or just behind it (Achilles tendinitis), where the Achilles tendon connects to the heel bone. 

Even though heel pain can be severe and sometimes disabling, it is rarely a health threat. Heel pain is typically mild and usually disappears on its own; however, in some cases the pain may persist and become chronic (long-term). 

In majority of cases, heel pain has a mechanical cause. It may also be caused by arthritis, infection, an autoimmune problem trauma, a neurological problem, or some other systemic condition (condition that affects the whole body). There may be various causes of heel pain like Plantar fasciitis (plantar fasciosis) -  inflammation of the plantar fascia, Heel bursitis - inflammation of the back of the heel, the bursa (a fibrous sac full of fluid) ,Heel bumps (pump bumps) ,Tarsal tunnel syndrome ,Chronic inflammation of the heel pad , Stress fracture,Severs disease (calcaneal apophysitis) ,Achilles tendonosis (degenerative tendinopathy). 
</p>
            <h4>Symptoms of heel pain</h4>
            <p>Heel pain typically comes on gradually, with no injury to the affected area. It is frequently triggered by wearing a flat shoe, such as flip-flop sandals. Flat footwear may stretch the plantar fascia to such an extent that the area becomes swollen or inflamed.In most cases, the pain is under the foot, toward the front of the heel.New research from the US suggests that wearing shoes such as high heels, pumps and sandals was strongly linked in women's later life with heel and ankle pain. </p>
            <h4>Treatments for heel pain </h4>
            <p>The following are common treatments for heel pain. 
            <ol> 
            <li>Non-steroidal anti-inflammatory drugs (After consultation with a doctor) </li>
            <li>Corticosteroids </li>
            <li>Home care such as rest, ice-pack use, proper-fitting footwear and foot supports are often enough to ease heel pain. </li>
            <li>Physical therapy (physiotherapy) </li>
            <li>Night splints </li>
            </ol>
            </p>
            <p>In cases that are not severe, home care is probably enough to get rid of heel pain.</p>
            <ol>
            <li>Rest - avoid running or standing for long periods, or walking on hard surfaces. Avoid activities that may stress the heels.</li>
            <li>Ice - place an ice-pack on the affected area for about 15 minutes. Do not place bare ice directly onto skin.</li>
            <li>Footwear - proper-fitting shoes that provide good support are crucial. Athletes should be particularly fussy about the shoes they use when practicing or competing - sports shoes need to be replaced at specific intervals (ask your trainer).</li>
            <li>Foot supports - wedges and heel cups and Insoles can help relieve symptoms.</li>
            </ol>
        </div>
        </div>
        <h1>FLAT FEET</h1>
        <div class="row">
        <div class="col-sm-4 justify-content-center">
        <img class="w-100 rounded mt-2" src={bg8}/>
        <img class="w-100 rounded mt-2" src={bg9}/>
        </div>
        <div class="col-sm-8  mt-2 mb-5">
            <p>
            Most people have a gap under the arch of their foot when they are standing. The arch, the inner part of the foot is slightly raised off the ground. People with flat feet or fallen arches either have no arch, or it is very low. 
            
The feet of people with fallen arches may roll over to the inner side when they are standing or walking, known as over pronation. The feet may point outward.  Some simple devices and exercises can help reduce the discomfort of flat feet. 

If there is no space under the arch, the person has flat feet. In a person with fallen arches, one or both feet may be flat on the ground, and shoes may wear unevenly, especially on one side, or they may wear out more quickly than usual.

Many people with fallen arches have no symptoms, but some may experience pain in their feet and even their back, depending on the cause. Symptoms can vary and generally depend on the severity of the condition. Some people have an uneven distribution of body weight and find that the heel of their shoes wears out more rapidly and more on one side than the other. The most common symptom of flat feet is pain. Pain may occur in the feet, if the connecting ligaments and muscles are strained.

The abnormal stresses on the knee and hip may result in pain. This is likely if the ankles turn inwards. Flat feet can also lead to pain in the lower back. There may also be stiffness in one or both feet.
            </p>
            <h4>Causes of flat feet include:</h4>
            <ol>
                <li>Genetic factors, as flat feet can run in families</li>
                <li>Weak arches, where the foot arch is visible for example, when sitting, but the foot flattens onto the ground when standing</li>
                <li>Foot or ankle injury</li>
                <li>Arthritis or rheumatoid arthritis</li>
                <li>Damage, dysfunction, or rupture of the posterior tibial tendon</li>
                <li>Nervous system or muscle diseases, such as cerebral palsy, muscular dystrophy, or spina bifida</li>
                <li>Tarsal coalition, where the bones of the foot fuse together in an unusual way, resulting in stiff and flat feet. This is most commonly diagnosed during childhood</li>
            </ol>
            <p>People are more likely to develop flat feet if they have obesity or diabetes, or during pregnancy.
Flat feet can also develop as people age. This can cause the posterior tibial tendon to weaken. This tendon is the main support structure of the arch of the feet.The tendon can become inflamed after overuse, known as tendinitis, or be torn. Damage to the tendon may cause the arch shape of the foot to flatten.
Flat feet can happen because of a developmental fault that occurs during childhood, or that develops with age, or after pregnancy.
Some people appear to have a very low arch or no arch without ever experiencing problems. Fallen arches or flat feet only need attention if they lead to discomfort, if they indicate another underlying disorder, or if they can lead to future pain elsewhere in the body.
Usage of Customised Insoles from FootPro can help to correct the deformity by offering adequate arch support and brings in pain relief by offering the necessary arch support. These insoles have to be used continuously as there is little possibility of formation of arches after adulthood.
</p>
<h4>Flat feet in children </h4>
<p>Children and infants often look as if they have flat feet. The arch is usually there, but the feet are still forming. In time, the arch will appear as normal. The extra fat on an infant’s foot may also be hiding the arch. Having apparently flat feet during early childhood does not mean that the person will always have flat feet. Children with flat feet can be assisted with an arch support (Right Insoles), before the bones and tendons become rigid and this will assist the body to form an arch.</p>
        </div>
        </div>
        <h1>ANKLE PAIN</h1>
        <div class="row">
        <div class="col-sm-4 justify-content-center">
        <img class="w-100 rounded mt-2" src={bg10}/>
        <img class="w-100 rounded mt-2" src={bg11}/>
        </div>
        <div class="col-sm-8  mt-2 mb-5">
        <p>Ankle pain refers to any type of pain or discomfort in your ankles. This pain could be caused by an injury, like a sprain, or by a medical condition,
        such as arthritis. An ankle sprain is one of the most common causes of ankle pain-making up 85 percent of all ankle injuries. 
        A sprain occurs when your ligaments (the tissues that connect bones) tear or get overstretched.</p>
        <h4>Causes of Ankle Pain </h4>
        <p>A sprain is a common cause of ankle pain. Pain can also be a result of: 
        arthritis (specifically osteoarthritis)
        gout
        nerve damage or injury, such as sciatica
        blocked blood vessels
        infection in the joint 
        </p>
        <p>For immediate at-home treatment of ankle pain, the RICE method is recommended. This includes:</p>
        <ol>
            <li>Rest — Avoid putting weight on your ankle. Try to move as little as possible for the first few days. Use crutches or a cane if you have to walk or move.</li>
                <li>Ice — Begin by putting a bag of ice on your ankle for at least 20 minutes at a time. Do this three to five times a day for three days after the injury. This helps reduce swelling and numb pain. Give yourself about 90 minutes between icing sessions.</li>
                <li>Compression — Wrap your injured ankle with an elastic bandage, like an ACE bandage. Do not wrap it so tightly that your ankle becomes numb or that your toes turn blue. </li>
                <li>Elevation — Whenever possible, keep your ankle raised above heart level on a stack of pillows or other type of support structure. </li>
            </ol>
            <p>
            Once your pain subsides, gently exercise your ankle by rotating it in circles. Rotate both directions, and stop if it begins to hurt. 
            You can also use your hands to gently flex the ankle up and down. 
            These exercises will return your range of motion and help exercise your ankle,
            lowering your risk of re-injury. </p>
            <p>If your ankle pain is a result of arthritis, you will not be able to heal or eliminate the pain. However, there are ways you can manage it. It may help to:</p>
         <ol>
            <li>Use topical pain relievers or Non Steroidal anti-inflammatory drugs after consulting a doctor</li>
            <li>Stay physically active and follow a fitness program focusing on moderate exercise</li>
            <li>Practice healthy eating habits</li>
            <li>Stretch to maintain a good range of motion in your joints</li>
            <li>Keep your body weight within a healthy range, which will lessen stress on the joints</li>
         </ol> 
        </div>
        </div>

        <h1>FORE FOOT PAIN</h1>
        <div class="row ">
        <div class="col-sm-4 justify-content-center">
        <img class="w-100 rounded mt-2" src={bg12}/>
        <img class="w-100 rounded mt-2" src={bg13}/>
        <img class="w-100 rounded mt-2" src={bg14}/>
        </div>
        <div class="col-sm-8  mt-2 mb-5">           
            <p>
             The forefoot includes that area of the foot also known as the ball-of- the-foot. The forefoot is a unique area of the foot that creates the final terminus of the foot and ground at the toe-off stage of gait. The forefoot sustains a significant amount of load during gait and is therefore susceptible to a number of unique problems.   
            </p>
            <h4>SYMPTOMS</h4>
            <p>The symptoms of forefoot pain vary with each individual forefoot problem. The causes and contributing factors of forefoot pain are many and include excessive time spent on the feet, poorly fitting shoes and trauma. The treatment of forefoot pain is specific to each individual condition.  Forefoot pain that does not respond to a reasonable period of conservative care should be evaluated by your podiatrist or orthopedist. Wearing FootPro Insoles and Footwear helps to reduce the pressure on the forefoot area and greatly relieve the pain in forefoot area.
            </p>  
        </div>
        </div>
        
        <h1>LEG LENGTH DISCREPENCY</h1>
        <div class="row">
        <div class="col-sm-4 justify-content-center">
        <img class="w-100 rounded mt-2" src={bg15}/>
        <img class="w-100 rounded mt-2" src={bg16}/>
        </div>
        <div class="col-sm-8  mt-2 mb-5">           
            <p>
            A discrepancy in leg length will usually become obvious to parents as they watch their child grow and begin to crawl and walk. Some children are born with legs of different lengths. In other cases, illness or injury causes a discrepancy in length to develop over time. While a slight difference in leg length may not cause symptoms, a significant difference can cause a noticeable limp and make it difficult for a child to run and play.
            Treatment for a discrepancy depends upon the severity. In many cases, a minor difference in leg length can be evened out by wearing a lift in one shoe. A child with a more significant difference, however, may benefit from surgery to make his or her legs the same length.               
            </p>
            <h4>Previous Injury to a Bone in the Leg </h4>
            <p>
            A broken leg bone can lead to a limb length discrepancy if it heals in a shortened position. This is more likely to happen if the bone was broken into many pieces. It is also more likely to happen if the skin and muscle tissue around the bone were severely injured and exposed, as occurs in an open fracture. In a child, a broken bone sometimes grows faster for several years after healing, causing it to become longer than the bone on the opposite side. This type of overgrowth occurs most often in young children with femur (thighbone) fractures. 
            Alternatively, a break in a child's bone through the growth plate near the end of the bone may cause slower growth, resulting in a shorter leg. 
            </p>  
            <h4>Bone Infection </h4>
            <p>Bone infections that occur in growing children may cause a significant limb length discrepancy. This is especially true if the infection happens in infancy.  
            Certain bone diseases may also cause limb length discrepancy.</p>
             <h4>Symptoms </h4>
             <p>Patients who have differences of 3.5 to 4 percent of total leg length (about 4 cm or 2/3 inches in an average adult) may limp or have other difficulties when walking. Because these differences require the patient to exert more effort to walk, he or she may tire easily. </p>
             <h4>Nonsurgical Treatment </h4>
             <p>For patients with minor limb length discrepancies (less than 1 inch) and no deformity, treatment is usually nonsurgical in nature. Because the risks of surgery may outweigh the benefits, surgical treatment to equalize small differences in leg length is not usually recommended. </p>
             <h4>Nonsurgical treatments may include: </h4>
             <p>Observation. If your child has not yet reached skeletal maturity, your doctor may recommend simple observation until growing is complete. During this time, your child will be reevaluated at regular intervals to determine whether the discrepancy is increasing or remaining the same.   
                Wearing a shoe lift. A lift fitted to the inside or outside of the shoe can often improve a patient's ability to walk and run. A shoe lift may also relieve back pain caused by a smaller limb length discrepancy. Shoe lifts are inexpensive and can be removed easily if they are not effective. 
                Insoles help to correct limb length discrepancy easily . The patient is able to walk without limping with correction insoles. Correction is possible for varying lengths with Insoles. Also, our insoles neatly fit inside the shoe or footwear and is not easily identifiable. 
            </p> 
            <h4>Surgical Treatment </h4>
            <p>Please consult a doctor for detailed information on corrections with surgery.</p>
        </div>
        </div>
        <h1>DIABETICS FOOT CONDITIONS</h1>
        <div class="row">
        <div class="col">   
        <h4>DIABETIC FOOT ULCER</h4>     
            <p>Diabetic foot ulcer is a major complication of diabetes mellitus.</p>
            <p>Ulcers form due to a combination of factors, such as lack of feeling in the foot, poor circulation, foot deformities, irritation (such as friction or pressure), and trauma, as well as the duration of diabetes. Your podiatrist can test feet for neuropathy with a simple, painless tool.</p>
            <p>In people who have good circulation and good medical care, an ulcer sometimes can heal in as few as three to six weeks. Deeper ulcers may take 12 to 20 weeks.</p>
            <p>A diabetic foot is a foot that exhibits any pathology that results directly from diabetes mellitus or any long-term or chronic complication of diabetes mellitus. Due to the peripheral nerve dysfunction associated with diabetes (diabetic neuropathy), patients have a reduced ability to feel pain.</p>
            <p>Our insoles also provides the necessary arch support for the weak arches in diabetic patients. The patient is advised to walk regularly using our customized insoles to aid in circulation. Our footwear is made with anti-bacterial and anti-fungal lining to prevent infections. Also, our footwear is designed to have no sharp corners to prevent rubbing and subsequent bruises or injury.</p>
            <h4>DIABETES AND COMMON FOOT PROBLEMS </h4>
            <p>Diabetic patients, having higher level of glucose (sugar) in their blood for a long time can cause some serious health issues, including foot problems.</p>
            <h4>How Can Diabetes Affect Your Feet?</h4>
            <p>Diabetes can cause two major problems that can affect your feet:</p>  
            <h4>Diabetic neuropathy:</h4> 
            <p>Uncontrolled diabetes can damage your nerves system. 
            If you have damaged nerves in your legs and feet, you might not feel heat, cold, or pain.
            This is called “sensory diabetic neuropathy.” If you do not feel a cut or sore on your foot 
            because of neuropathy, the cut could get worse and become infected. The muscles of the foot
            may not function properly, because the nerves that make the muscles work are damaged. This
            could cause the foot to not align properly and create too much pressure in one area of the foot.
            It is estimated that up to 10% of diabetic patients will develop foot ulcers. Foot ulcers occur 
            because of nerve damage and peripheral vascular disease. Use Micro Cellular Rubber (MCR)
            footwear or Micro Cellular Polymer (MCP) footwear to prevent diabetic neuropathy.
            </p>
            <h4>Peripheral vascular disease:</h4>
            <p> Diabetes also affects the flow of blood. Without good blood flow, it takes
            longer for a sore or cut to heal. Poor blood flow in the arms and legs is called “peripheral vascular
            disease.” Peripheral vascular disease is a circulation disorder that affects blood vessels away from
            the heart. If you have an infection that will not heal because of poor blood flow, you are at risk
            for developing ulcers or the death of tissue due to a lack of blood. Use cushioned pads and insoles 
            in your shoes or use MCR/MCP footwear.
            </p> 
            <h4>Other Common Foot Problems with Diabetes:</h4>
            <p>Anyone can get the foot problems listed below. For people with diabetes, however, these common foot problems can possibly lead to infection and serious health issues such as
            Athlete's foot is a fungus that causes itching, redness, and cracking. Germs can enter through the cracks in your skin and cause an infection. Medicines that kill the fungus are used to treat athlete's foot. These medicines may be pills and/or creams applied directly to the problem area. Athlete patients should use Micro Cellular Rubber (MCR) footwear or Micro Cellular Polymer (MCP) footwear.
            Calluses is a build-up of hard skin, usually on the underside of the foot. Calluses are caused by an uneven distribution of weight, generally on the bottom of the forefoot or heel. Calluses also can be caused by improperly fitting shoes or by a skin abnormality. Keep in mind that some degree of callus formation on the sole of the foot is normal. Proper care is necessary if you have a callus. After your bath or shower, use a pumice stone to gently remove the build-up of tissue. Use cushioned pads and insoles in your shoes or use MCR/MCP footwear. Medications also may be prescribed to soften calluses. DO NOT try to cut the callus or remove it with a sharp object </p>
            <p>Corn is a build-up of hard skin near a bony area of a toe or between toes. Corns may be the result of pressure from shoes that rub against the toes or cause friction between the toes. Proper care is necessary if you have a corn - use cushioned pads and insoles in your shoes or use MCR/MCP footwear. After your bath or shower, use a pumice stone to gently remove the build-up of tissue. Do not use over-the-counter remedies to dissolve corns. DO NOT try to cut the corn or remove it with a sharp object.</p>
            <p>Blisters can form when your shoes rub the same spot on your foot. Wearing shoes that do not fit properly or wearing shoes without socks can cause blisters, which can become infected. When treating blisters, it's important not to “pop” them. The skin covering the blister helps protect it from infection. Use an antibacterial cream and clean, soft bandages to help protect the skin and prevent infection. Also, use cushioned pads and insoles in your shoes or use MCR/MCP footwear.</p>
            <p>Bunion forms when your big toe angles in toward the second toe. Often, the spot where your big toe joins the rest of the foot becomes red and callused. This area also may begin to stick out and become hard. Bunions can form on one or both feet. They may run in the family, but most often are caused by wearing high-heeled shoes with narrow toes. These shoes put pressure on the big toe, pushing it toward the second toe. The use of cushioned pads and insoles in your shoes or use MCR/MCP footwear may help protect the bunion from irritation.</p>
            <p>Hammertoe is a toe that is bent because of a weakened muscle. The weakened muscle makes the tendons shorter, causing the toes to curl under the feet. Hammertoes can run in families. They can also be cause by shoes that are too short. Hammertoes can cause problems with walking and can lead to other foot problems, such as blisters, calluses, and sores. The use of cushioned pads and insoles in your shoes or use MCR/MCP footwear may help protect the hammertoe from irritation.</p>
             
            
            <h4>PREVENTIVE PROCEDURES</h4>
            
            <p>
            Along with treating diabetic foot conditions, there are other things we can do to prevent further problems. If you’ve had foot or vascular surgery to correct a problem, we’ll monitor you over the months and years after.
            If we determine at some point down the road that there’s a new area of pressure developing, we may be able to correct it before it becomes a problem. Surgical examples include tendon lengthening or osteotomy, in which we cut or reshape a bone to relieve pressure.</p>
            <p>We also may be able to avoid surgery with a non-invasive procedure. If, for example, you have hammertoe, in which the toe bends downward causing repeated ulcers on the tip of the toe, we could perform a percutaneous needle tenotomy. In this procedure, we use a needle to release part of the tendon from the bone.
            Diabetes care doesn’t stop at controlling blood sugar. If you or a loved one has diabetes, get in the habit of practicing proper foot care, scheduling regular check-ups and seeking early treatment for problems to prevent serious diabetic foot complications, including amputation.
            Last but not the least, use MCR footwear to prevent your feet from all the above foot problems.
            </p> 
            <h4>Diabetic Foot Care Tips:</h4>                           
            <ol>                                            
            <li>Diabetic patients need to care their feet daily</li>
            <li>Avoid using Pointed Shoes</li>
            <li>Its Important to check is there any Cut or Infection on daily basis</li>
            <li>Avoid using Hot waters to wash your feet will damage your skin, Safe hot water level should be 32 degree C to 35 degree C</li>
            <li>Dry your feet well using a soft cloth</li>
            <li>Apply soft lotion top and bottom of feet to keep the skin soft.</li>
            <li>Avoid using Heating pads</li>
            <li>Keep your blood glucose level targeted range</li>
            <li>Use MCR Footwear/Slippers or Chappals or Sandals and Diabetic Shoes to take care of your feet.</li>
        </ol>
        </div>
        </div>
      </div>
  );
}