import React from 'react';
import bg from '../../../../../Assets/Images/Wallpaper1.jpg';

const TransFemoral=()=> {
return (
<div className="Pages-font-size">
<h2 class="text-center">Trans-Femoral</h2>
<div class="row container-fluid justify-content-center mt-3">
  <div class="col-sm-4 ">
  <img class="w-100 rounded mt-2" src={bg}/>
  <h6>Knee Dis-articulation Prosthesis</h6>
  <img class="w-100 rounded mt-2" src={bg}/>
  <h6>Above Knee Prosthesis</h6>
  <img class="w-100 rounded mt-2" src={bg}/>
  <h6>Hip Dis-articulation prosthesis</h6>
  </div>
  <div class="col-sm-8  mt-2 mb-5">
  </div>
</div>
</div>
);
}

export default TransFemoral;             