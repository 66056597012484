import React from "react";
import "./Kids.css";
import Newone from "../../Assets/MODELS/Kids New June 2022/mustard-yellow-0-designify.png";
import Newtwo from "../../Assets/MODELS/Kids New June 2022/mustard-yellow-1-designify.png";
import Newthree from "../../Assets/MODELS/Kids New June 2022/mustard-yellow-2-designify.png";
import Newfour from "../../Assets/MODELS/Kids New June 2022/mustard-yellow-3-designify.png";
import Newfive from "../../Assets/MODELS/Kids New June 2022/mustard-yellow-4-designify.png";
import Leathershoe from "../../Assets/MODELS/Kids New June 2022/mustard-yellow-designify.png";
import Leathertable from "../../Assets/MODELS/Kids New June 2022/image table.jpeg";
import Silicontable from "../../Assets/MODELS/Kids New June 2022/silicon table.jpeg";
import Siliconshoe from "../../Assets/MODELS/Kids New June 2022/Silicon shoe.png";
function Kids() {
  return (
    <>
      <h2 class="text-center mt-5">DOBS Bar</h2>
      <div class="row mt-2">
        <div class="col text-center mt-3">
          <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={Newone} alt="" />
          </div>
        </div>
        <div class="col text-center mt-3">
          <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={Newtwo} alt="" />
          </div>
        </div>
        <div class="col text-center mt-3">
          <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={Newthree} alt="" />
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col text-center mt-3">
          <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={Newfour} alt="" />
          </div>
        </div>
        <div class="col text-center mt-3">
          <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={Newfive} alt="" />
          </div>
        </div>
      </div>

      <div class="container-fluid col-sm-4 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-5">Active Spring Reset</h1>
        <p class="mt-4 ">
          A spring reset brings the child’s feet back to neutral in a resting
          position. The spring maintains an active dorsiflexion stretch while
          still allowing the child to play and crawl in the brace.
        </p>
      </div>
      <div class="container-fluid col-sm-4 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-4 ">Adjustable External Rotation</h1>
        <p class="mt-4 ">
          Easy to set external rotation dial with just a turn of a screw. We
          have provided 10 degree increments of external rotation
        </p>
      </div>
      <div class="container-fluid col-sm-4 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-5">Adjustbale Bar Width</h1>
        <p class="mt-4 ">
          Your Dynamic bar will adjust in length as the child grows, with a turn
          of a screw
        </p>
      </div>
      <div class="container-fluid col-sm-4 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-5">Universal Screws</h1>
        <p class="mt-4 ">
          Universal hex head screws will no longer leave you with a stripped out
          screw head. An allen key will be provided with each bar.{" "}
        </p>
      </div>
      <div class="container-fluid col-sm-4 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-5">Ponseti Quick Release</h1>
        <p class="mt-4 ">
          Quick Release mechanism allows shoes and Ponseti AFOs to snap on and
          off for diapering, carseats, and high chairs.
        </p>
      </div>
      <div class="container-fluid col-sm-8 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-0">Setting Up The DYNAMIC Bar</h1>
        <p class="mt-4 ">
          Setting the bar width. The DYNAMIC Bar should be set so that the width
          of the bar is equal to the shoulder width of the child. Measure the
          shoulder width of the child from the left outside shoulder to the
          right outside shoulder. Adjust the length of the DYNAMIC Bar so that
          the length of the bar is equal to your shoulder width measurement from
          mid-heel of the left footplate to mid-heel of the right foot plate. It
          is better to have the bar a little wider than shoulder width rather
          than too narrow. It is uncomfortable for the child if the bar length
          is too narrow.
          <br />
          Setting external rotation.The clubfoot should be set at about 60
          degrees of external rotation, which should match the degree of
          rotation of the foot in the last cast. If the foot was externally
          rotated only 50 degrees in the last cast the brace should also be at
          50 degrees. A normal, non-affected foot should be fixed on the bar in
          about 40 degrees of external rotation. Loosen screw in center hole.
          Position and fasten to quick clip. Locate indicator hole to set
          degree. Fasten bar with second screw in appropriate hole.
          <br />
          Attaching Ponseti AFOs. To attach the Ponseti AFOs you will need to
          locate the opening in the back of the AFOs. You will then Slide each
          Ponseti Adapter into the opening in the back of each AFO. Each AFO
          will be correctly attached after you hear a "click" indicating the
          tongue and grove system are conjoined correctly. 
        </p>
      </div>

      <div class="container-fluid col-sm-8 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-0">Instructions for Use</h1>
        <p class="mt-4 ">
          The DYNAMIC Bar should be worn 23 hours a day for the first 3 months
          and then at nighttime and naps. <br />
          Bracing is critical in maintaining the  correction of clubfeet. If the
          brace is not worn as prescribed, there is a near 100 percent
          recurrence rate.
        </p>
      </div>

      <div class="container-fluid col-sm-8 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-0">Advice to Parents</h1>
        <p class="mt-4 ">
          Play with your child in the brace. Babies might get fussy for the
          first few days after receiving a brace, and will require time to
          adjust. Playing with your child is the key to getting over the
          irritability quickly. Teach your child that he/ she can kick and swing
          the legs with the brace on by gently moving your child's legs up and
          down together and independently until he/she gets used to the brace.
          <br />
          Make it routine. Children do better if you develop a fixed routine for
          brace wear. During the three to four years of night/naptime wear, put
          the brace on anytime your child goes to the “sleeping spot.” They will
          figure out that when it is “that time of day” they need to wear the
          brace. Your child is less likely to fuss if this is a constant
          routine.
          <br />
          Pad the bar. By padding the bar you will protect yourself and your
          furniture from the metal bar when the child is wearing the brace.
          <br />
          Check your child's feet. It is important to check your child's feet
          several times a day after initiating the bracing to ensure no blisters
          are developing on the heel. Never use lotion on any red spots on the
          skin (lotion will make the problem worse). Some redness is normal with
          use. Bright red spots or blisters, especially on the back of the heel,
          usually indicate that the heel is slipping. Ensure that the heel stays
          down in the shoe by securing the straps and/ or buckles or by talking
          to your orthotist about placing a heel pad in the shoe.
          <br />
          Always wear cotton socks. Your child should always wear cotton socks
          under his/ her shoes, sandals or plastic AFO's. The sock should be a
          little higher than the top of the shoes, sandals or orthosis. Allow
          your child's toes some freedom.
        </p>
      </div>

      <div class="container-fluid col-sm-8 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-0">CHOOSING THE SHOE TYPE AND USAGE</h1>
        <p class="mt-4 ">
          1. The size of the shoe is same as size of the foot from tip of toe to
          back of heel in cm). Please check the table for shoe size . For
          example if foot length is 10.0 cm, size 8 shoe is appropriate and if
          foot length is 10.6 cm ,size 9 shoe is appropriate.
          <br />
          2. Our product comes with 2 types of shoes,one is Silicone and
          another is Leather based
          <br />
          3. SILICON BASED
        </p>
      </div>
      

      

      <div class="row mt-2">
        <div class="col text-center mt-3 ml-5">
          <img class="w-60 rounded" src={Leathertable} alt="" />
        </div>
        <div class="col text-center mt-3 mr-5">
          <img class="w-50 rounded" src={Leathershoe} alt="" />
          <h5 class="mt-2">Leather Shoe</h5>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col text-center mt-3 ml-5">
          <img class="w-60 rounded" src={Silicontable} alt="" />
        </div>
        <div class="col text-center mt-3 mr-5">
          <img class="w-50 rounded" src={Siliconshoe} alt="" />
          <h5 class="mt-2">Silicon Shoe</h5>
        </div>
      </div>

      {/* <div class="row">
        <div class="col text-center mt-3 ml-5">
          <div class="bg-light p-2 rounded">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Foot length in Centimeter</th>
                  <th scope="col">Shoe size (silicon)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>8.5 – 9.2</td>
                  <td>7</td>
                </tr>
                <tr>
                  <td>9.5-10.2</td>
                  <td>8</td>
                </tr>
                <tr>
                  <td>10.5 – 11.2</td>
                  <td>9</td>
                </tr>
                <tr>
                  <td>11.5-12.2</td>
                  <td>10</td>
                </tr>
                <tr>
                  <td>12.5-13.2</td>
                  <td>11</td>
                </tr>
                <tr>
                  <td>13.5-14.2</td>
                  <td>12</td>
                </tr>

                <tr>
                  <td>14.5-15.2</td>
                  <td>13</td>
                </tr>
                <tr>
                  <td>15.5-16.2</td>
                  <td>14</td>
                </tr>
                <tr>
                  <td>16.5-17.2</td>
                  <td>15</td>
                </tr>
                <tr>
                  <td>17.5-18.2</td>
                  <td>16</td>
                </tr>
                <tr>
                  <td>18.5-19.2</td>
                  <td>17</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col text-center mt-3">
          <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={Siliconshoe} alt="" />
            <h5 class="mt-2">Silicon Shoe</h5>
          </div>
        </div>
      </div> */}


      <div class="container-fluid col-sm-8 justify-content-center  Pages-font-size mt-3  ">
        <h1 class="ml-0">Usage Of Shoe</h1>
        <p class="mt-4 ">
          1. The shoes should be worn over a pair of cotton socks and released once in an hour or two hours for 15-20 minutes during day time. <br />
          2. Our shoe comes in both silicon and leather based(size varies on silicon and leather varients.
          <br />
          3 .As the child grows the foot size increases . When the great toe starts coming out of the front margin of the shoe it is time to order the next size.
        <br />
         4. Please use strap and shoelace both for shoe.
         <br />
         5. Heel must touch the sole ( There is a gap at back of shoe for checking).
         <br />
         6. Purpose of brace / shoe in CTEV is to maintain the corrected deformity ( to prevent recurrence) . If the foot is curved / bent , please consult your doctor.
        </p>
      </div>
    </>
  );
}

export default Kids;
