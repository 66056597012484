import React from 'react';
import bg from '../../../../../Assets/Images/Wallpaper1.jpg';

const TransTibil=()=> {
return (
<div className="Pages-font-size">
<h2 class="text-center">Trans-tibial</h2>
<div class="row container-fluid justify-content-center mt-3">
  <div class="col-sm-4 ">
  <img class="w-100 rounded mt-2" src={bg}/>
  <h6>Below Knee (Trans-tibial) Prosthesis</h6>
  <img class="w-100 rounded mt-2" src={bg}/>
  <h6>Syme’s Prosthesis</h6>
  <img class="w-100 rounded mt-2" src={bg}/>
  <h6>Partial Foot Prosthesis</h6>
  </div>
  <div class="col-sm-8  mt-2 mb-5">
  </div>
</div>
</div>
);
}


export default TransTibil;             














