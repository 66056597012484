import React from 'react';
import {BrowserRouter as Router, Switch, Route, NavLink} from "react-router-dom";

import Aboutus from './Components/About/Aboutus';
import Contact from './Components/Contact/contact';

import Fcare from './Components/Footcare/Fcare';
import ToeSeparator from './Components/Footcare/Pages/ToeSeparator';
import MetartasalBar from './Components/Footcare/Pages/MetartasalBar';
import MedicalArchSupport from './Components/Footcare/Pages/MedicalArchSupport';
import Lateral from './Components/Footcare/Pages/Lateral';

import Home from  "./Components/Home/home"
import Special from './Components/Home/special';

import logo from "./Assets/Images/logo.jpeg"
// import Pages from './Components/Pages/pages'
import Footer from './Components/Footer/footer'
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './App.css'
import './navcss.css' 
import Dropdown from './Components/Dropdown/Dropdown';


import Services from './Components/Services/Ourservices'

import Orthoses from './Components/Services/Pages/Orthoses/Orthoses'

import Silione from './Components/Services/Pages/Prothesis/Aesthetic(Silicone)Restoration/Silicon'
import TransFemorl from './Components/Services/Pages/Prothesis/LowerExtremityProsthesis/TransFemoral'
import TransTibil from './Components/Services/Pages/Prothesis/LowerExtremityProsthesis/TransTibil'
import Fitness from './Components/Services/Pages/Prothesis/ProsthesisForSpecialNeeds/Fitness'
import WaterProof from './Components/Services/Pages/Prothesis/ProsthesisForSpecialNeeds/WaterProof'
import Cosmetic from './Components/Services/Pages/Prothesis/UpperExtremityProsthesis/Cosmetic'
import Mechanical from './Components/Services/Pages/Prothesis/UpperExtremityProsthesis/Mechanical'
import MyoElectric from './Components/Services/Pages/Prothesis/UpperExtremityProsthesis/MyoElectric'
import Mail from './Components/Contact/contact2';
import CustomNade from './Components/Services/Pages/CustomMade';
import Mcp from './Components/Services/Pages/Mcr__Mcp';
import AnkleBoot from './Components/Services/Pages/AnkleBoot';
import Prefab from './Components/Services/Pages/Prefab';
import WoundCare from './Components/Services/Pages/WoundCare';
import All from './Components/Footcare/All.js';
import Mens from './Components/Products/Mens';
import Ladies from './Components/Products/Ladies';
import Kids from './Components/Products/Kids';

class App extends React.Component {
  render() {
  return (                                                                          
    <div>
    <Router>
      <Dropdown/>
      <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/contact" component={Contact}/>
      <Route exact path="/special" component={Special}/>
      <Route exact path="/about" component={Aboutus}/>
      <Route exact path="/custom" component={CustomNade}/>
      <Route exact path="/orthotics" component={Orthoses}/>
      <Route exact path="/mail" component={Mail}/>
      <Route exact path="/mcp" component={Mcp}/>
      <Route exact path="/footcare" component={Fcare}/>
      <Route exact path="/topseparator" component={ToeSeparator}/>
      <Route exact path="/metartasalbar" component={MetartasalBar}/>
      <Route exact path="/medicalarchsupport" component={MedicalArchSupport}/>
      <Route exact path="/lateral" component={Lateral}/>
      <Route exact path="/ankle" component={AnkleBoot}/>
      <Route exact path="/male-products" component={Mens}/>
      <Route exact path="/kids-products" component={Kids}/>
      <Route exact path="/female-products" component={Ladies}/>
      <Route exact path="/service" component={Services}/>
      <Route exact path="/prefab" component={Prefab}/>
      <Route exact path="/orthotics" component={Orthoses}/>
      <Route exact path="/woundcare" component={WoundCare}/>
      <Route exact path ="/silicone" component={Silione}/>
      <Route exact path="/trans-femoral" component={TransFemorl}/>
      <Route exact path="/trans-tibil" component={TransTibil}/>
      <Route exact path="/fitness" component={Fitness}/>
      <Route exact path="/waterproof" component={WaterProof}/>
      <Route exact path="/cosmetic" component={Cosmetic}/>
      <Route exact path="/mechanical" component={Mechanical}/>
      <Route exact path="/myoelectric" component={MyoElectric}/>
      <Route exact path="/All" component={All}/>
      </Switch>
      <a href="https://wa.me/919123510990" class="whatsapp_float" target="_blank"> <i class="fa fa-whatsapp whatsapp-icon"></i></a>
    <Footer/>
    </Router>
    </div>
  );
}
}
export default App;