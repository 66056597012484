import React from "react";
import one from "../../Assets/MODELS/MALE/GAIT SHOE.jpg";
import two from "../../Assets/MODELS/MALE/VENTO.jpg";
import three from "../../Assets/MODELS/MALE/VENTO WITH LACE.jpg";
import four from "../../Assets/MODELS/MALE/V - STRAP.jpg";
import five from "../../Assets/MODELS/MALE/TRUCKER.jpg";
import six from "../../Assets/MODELS/MALE/SUPERIOR.jpg";
import seven from "../../Assets/MODELS/MALE/SUPERIOR STRAPED.jpg";
import eight from "../../Assets/MODELS/MALE/GRILLER.jpg";
import nine from "../../Assets/MODELS/MALE/ROLEX.jpg";
import ten from "../../Assets/MODELS/MALE/ROVER.jpg";
import eleven from "../../Assets/MODELS/MALE/orangeshoe.jpg";
import twelve from "../../Assets/MODELS/MALE/shoe.jpg";
function Mens() {
  return (
    <>
      {/* <div class="row mt-2">
        <div class="col-lg-3 col-md-12 mb-4 mb-lg-0">
          <img src={one} class="w-100 shadow-1-strong rounded mb-4" alt="" />
          <h5>One </h5>
          <img src={two} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>
        <div class="col-lg-3 mb-4 mb-lg-0">
          <img src={three} class="w-100 shadow-1-strong rounded mb-4" alt="" />
          <img src={four} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>
        <div class="col-lg-3 mb-4 mb-lg-0">
          <img src={five} class="w-100 shadow-1-strong rounded mb-4" alt="" />
          <img src={six} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>

        <div class="col-lg-3 mb-4 mb-lg-0">
          <img src={seven} class="w-100 shadow-1-strong rounded mb-4" alt="" />
          <img src={eight} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-3 mb-4 mb-lg-0">
          <img src={nine} class="w-100 shadow-1-strong rounded mb-4" alt="" />
          
        </div>
        <div class="col-lg-3 mb-4 mb-lg-0">
          <img src={ten} class="w-100 shadow-1-strong rounded mb-4" alt="" />
          
        </div>
        <div class="col-lg-3 mb-4 mb-lg-0">
          <img src={eleven} class="w-100 shadow-1-strong rounded mb-4" alt="" />
          
        </div>
        <div class="col-lg-3 mb-4 mb-lg-0">
          <img src={twelve} class="w-100 shadow-1-strong rounded mb-4" alt="" />
        </div>
      </div> */}
 
    <h2 class="text-center mt-5">FootWare and Shoes For Men</h2>
    <div class="row mt-2">
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={one} alt=""/>
            <h5>Gait Shoe</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={two} alt=""/>
            <h5>Vento</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={three} alt=""/>
            <h5>Vento with Lace</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={four} alt=""/>
            <h5>V-strap</h5>
        </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={five} alt=""/>
            <h5>Trucker</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={six} alt=""/>
            <h5>Superior</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={seven} alt=""/>
            <h5>Superior Straped</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={eight} alt=""/>
            <h5>Griller</h5>
        </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={nine} alt=""/>
            <h5>Rolex</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={ten} alt=""/>
            <h5>Rover</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={eleven} alt=""/>
            <h5>Vento with Lace</h5>
        </div>
        </div>
        <div class="col text-center mt-3">
        <div class="bg-light p-2 rounded">
            <img class="w-100 rounded" src={twelve} alt=""/>
            <h5>V-strap</h5>
        </div>
        </div>
    </div>

    </>
  );
}

export default Mens;
