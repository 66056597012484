import React from 'react';
import Special from './special';
import Contact from '../Contact/contact';
import Slider from './slider'
import './home.css'
import Front from './Front';
import ProductsHome from '../Products/ProductsHome';
const Home=()=>{
    return(
    <div class="w-100">
        <Front/>
    {/* <Slider/> */}
    {/* <Special/> */}

    <ProductsHome/>
    <Contact/>
    
   </div>
    )
};

export default Home;