import React from 'react'
import "./Products.css"
import gait from "../../Assets/MODELS/MALE/GAIT SHOE.jpg"
import vaagai from "../../Assets/MODELS/FEMALE/VAAGAI.jpg"
import kid from "../../Assets/MODELS/KIDS/AGAXY.jpg"
import Mens from './Mens'
import { Link } from 'react-router-dom'
import Kids from './Kids'
function ProductsHome() {
    return (
        <>
        <section class="section promotion">
        <div class="titleproduct">
          <h2>Our Products</h2>
          <span>Our Products is the One and Only Product for ur Foot Solution</span>
        </div>
    
        <div class="promotion-layout container">
          <div class="promotion-item">
            <img src={gait} alt="" />
            <div class="promotion-content">
              <h3>Mens</h3>
              <Link to ="/male-products">SHOP NOW</Link>
              
            </div>
          </div>
    
          <div class="promotion-item">
            <img src={kid}  alt="" />
            <div class="promotion-content">
              <h3>Kids</h3>
              <Link to ="/kids-products">SHOP NOW</Link>
            </div>
          </div>
    
          <div class="promotion-item">
            <img src={vaagai}  alt="" />
            <div class="promotion-content">
              <h3>Ladies</h3>
              <Link to ="/female-products">SHOP NOW</Link>
            </div>
          </div>
    
        
    
            </div>
      </section>
      {/* <Mens/> */}
      </>
    )
}

export default ProductsHome
